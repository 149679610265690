#partner-section {
	align-items: center;
	background:rgb(238, 247, 248);
	display: flex;
    flex-direction:column;
	justify-content: center;
    padding:50px 0 70px;
}
@keyframes scroll {
    0% {
        transform: translateX(0);
   }
    100% {
        transform: translateX(calc(-250px * 7));
   }
}
.slider {
    background: white;
    box-shadow:0px 1px 5px rgba(0, 0, 0, 0.1);
    height: 120px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.slider::before, .slider::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.slider::before {
    left: 0;
    top: 0;
}
.slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(280px * 14);
}
.slider .slide {
    height: 120px;
    width: 280px;
}
.partner{
    color: red;
    padding-top: 32px;
}
