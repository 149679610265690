.form-group {
    margin-bottom: 1rem;
    color: #555555!important;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input {
    border: 1px solid #ededed;
    padding: 8px 15px;
    font-size: 14px;
    color: #a7400e;
    font-style: bold;
    border-radius: 0;
}
form.paypal_donate_form label {
    padding: 0 15px 15px 0;
    font-weight: bold;
}

.qr{
    height: 187px !important;
}; 