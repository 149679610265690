footer{
    background-color: #151b26;
    padding: 80px 0;
    color:#fff;
}
.newsletterForm {
    position: relative;
    border-radius: 3px;
    height: 50px;
    margin-bottom: 40px;
}

.newsletterForm input {
    width: 100%;
    border-radius: 3px;
    height: 100%;
    padding: 0 65px 0 15px;
}
.newsletterForm button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: #009ba5;
    bottom: 0;
    padding: 0 18px;
    border-radius: 3px;
    cursor: pointer;
}
.newsletterForm button span {
    color: #fff;
    font-size: 16px;
}
.fa-paper-plane:before {
    content: "\f1d8";
}
.footer-nav h3{
        text-transform: uppercase;
        color: #fff;
        font-size: 16px;
        margin-bottom: 24px;
        font-weight: bold;
}
.footer-nav p {
    margin: 0;
    padding: 0;
    line-height: 24px;
    color: #eee;
    opacity: 0.5;
}
.footer-nav li {
    list-style: none;
    margin-bottom: 12px;
    opacity: 0.5;
}
.footer-nav li a {
    color: #f5f6f6;
    font-weight: 400;
}
.follow li {
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
    opacity: 1 !important;
}
.follow ul li a {
    color: #5f5f5f !important;
}

@media (max-width: 750px){
    footer .row{
        text-align:center!important;
    }
}