#event-section{
    padding: 50px 0 50px;
}
.image-block-one img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.event-card {
    background-color: #fafafa;
    border: 1px dashed #ddd;
}
.event-details{
    padding: 30px 15px;
}
.event-info {
    margin-bottom: 10px;
}
.event-info p {
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #999;
}
.event-title h3 {
    position: relative;
    margin-bottom: 10px;
    display: inline-block;
    padding-right: 50px;
    font-size: 20px;
    font-family: PonyTale;
    font-weight:600;
}

@media (max-width: 750px){
    #event-section{
        padding: 0 0 50px!important;
    }
    .event-first-row{
        flex-direction:column!important;
    }
}