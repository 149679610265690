.causes-section{
    padding:100px 0 50px;
}
.section-heading h2 {
    font-size: 38px;
    margin-bottom: 0;
}
.section-heading .heading-border {
    border-top: 4px solid #009ba5;
    width: 50px;
    display: inline-block;
    margin-top: 0;
}
.card{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border-radius:4px;
    height:100%;
}
.card-top{
    position:relative;
}
.causes-img-container {
    overflow:hidden;
    background: #000000;
    background: linear-gradient(to right, #009ba5, #000000);
    height:205px;
}
.causes-img-container img{
    height:100%;
    object-fit:cover;
    width:100%;
}
.card-top .cause-type{
    position:absolute;
    bottom:-20px;
    left:20px;
    background:#009ba5;
    color:#fff;
    border:none;
    border-radius:50px;
    font-weight:500;
}
.causes-img-container img {
    transition: all .5s;
    cursor: pointer;
    width:100%;
    object-fit:cover;
}
.causes-img-container img:hover{
    transform: scale(1.2);
    opacity: 0.4;
    transition: all .5s;
}
.card-content{
        padding: 30px;
}
.card-content h3 {
    font-size: 25px;
    font-weight: 700;
    color: #010212;
    margin: 15px 0;
}
.progress-level{
    padding-bottom: 20px;
    padding-top: 15px;
}
.bar {
    height: 16px;
    background: #eeeeee;
    border-radius: 5px;
}
.bar-inner {
    /* position: relative; */
   display:flex;
   align-items:center;
    width: 100%;
    height: 16px;
}
.bar-fill {
    position: relative;
    border-radius:8px;
    height: 8px;
    background: #009ba5;
}
.text-grey{
        --bs-text-opacity: 1;
        color: rgba(0, 0, 0, .5)!important;
}
.percent {
    position: absolute;
    right: 0;
    top: -3px;
    font-size: 12px;
    font-weight: 700;
    opacity: 1;
    background-color: #009ba5;
    border-radius: 100%;
    border:2px solid #fff;
    width: 15px;
    height: 15px;
}
.number{
    position: absolute;
    right: 0;
    top: -26px;
}