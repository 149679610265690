#about-us h1 {
    font-size: 30px;
    padding: 0 0 20px 0;
    line-height: 40px;
    position: relative;
    margin: 0 0 20px 0;
}
#about-us h2 {
    font-size: 24px;
}
#about-us h1 span {
    color: #009ba5;
}

#about-us h1:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 65px;
    height: 2px;
    background: #009ba5;
}
.default-link {
    position: relative;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.03em;
    padding-bottom: 5px;
    color: #2f3753;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration:none!important;
}
.default-link:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 35px;
    border-bottom: 2px solid #dc4549;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.about-feature-box .about-feature {
    float: left;
    padding: 0 0;
    margin: 0 0 0px;
    width: 50%;
    position:relative;
}
.about-feature-box .about-feature .inner-box {
    position: relative;
    display: block;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
    transition: all 300ms linear;
    cursor:pointer;
}
.about-feature-box .about-feature:nth-child(1) .inner-box {
    background: #f5a93f;
}
.about-feature-box .about-feature:nth-child(1) .inner-box:hover {
    transform: translate(-10px,-10px);
}
.about-feature-box .about-feature:nth-child(2) .inner-box {
    background: #4cc7d6;
}
.about-feature-box .about-feature:nth-child(2) .inner-box:hover {
    transform: translate(10px,-10px);
}
.about-feature-box .about-feature:nth-child(3) .inner-box {
    background: #3e688f;
}
.about-feature-box .about-feature:nth-child(3) .inner-box:hover {
    transform: translate(-10px,10px);
}
.about-feature-box .about-feature:nth-child(4) .inner-box {
    background: #dc4549;
}
.about-feature-box .about-feature:nth-child(4) .inner-box:hover {
    transform: translate(10px,10px);
}
.about-feature-box .about-feature .content-box {
    position: relative;
    display: table;
    width: 100%;
    height: 200px;
    vertical-align: middle;
    padding: 20px 20px;
    text-align: center;
    border-radius: 0px;
    overflow: hidden;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.about-feature-box .about-feature .inner-box .inner {
    position: relative;
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}
.about-feature-box .about-feature h3 {
    position: relative;
    display: inline-block;
    max-width: 140px;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.3em;
    color:#fff
}

#about-us .img-fluid{
    height:320px;
    object-fit:cover;
}

@media (max-width: 750px){
    .about-first-row{
        flex-direction:column-reverse!important;
    }
}