.navbar{
    width: 100%;
    height: 80px;
    position: sticky!important;
    top:0;
    z-index: 999;
    background-color: #fff;
    transition: background-color .3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.navbar-brand img{
    width:90px;
    height:60px;
}
.btn-donate{
    background:#009ba5!important;
    font-weight:500;
    border:none!important;
}
.navbar-nav{
    background:#fff;
}
.nav-link{
    cursor:pointer;
}
  .nav-link::after{
    content:'';
    width:0%;
    height:2px;
    background:#009ba5;
    display:block;
    margin:auto;
    transition: 0.5s;
    cursor:pointer;
}
.nav-link:hover::after{
    width:100%;
 }

 @media (max-width: 750px){
    .navbar-nav{
        text-align:center;
    }
 }